import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const PaginaInicial = React.lazy(() => import("../Paginas/PaginaInicial"));
const Servicos = React.lazy(() => import("../Paginas/Servicos"));
const Empresa = React.lazy(() => import("../Paginas/Empresa"));

const Rotas = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<PaginaInicial />} />
        <Route exact path="/servicos" element={<Servicos />} />
        <Route exact path="/empresa" element={<Empresa />} />
      </Routes>
    </Router>
  );
};

export default Rotas;
