
export default {
    translations: {
      cabecalho: {
        empresaMenu: 'Empresa',
        servicoMenu: 'Serviços',
        contatoMenu: 'Contato'
      },
      PaginaInicial: {
        tituloAduaneira: 'Gestão Aduaneira',
        textoAduaneira: 'Oferecemos ao mercado um conceito eficiente e tecnológico nos serviços de desembaraço aduaneiro nos processos de importação e exportação de cargas. São mais de 20 anos de experiência com foco em um atendimento personalizado aos clientes, isto proporcionou a GA ser reconhecida como referência em assessoria aduaneira em todo o Brasil.',
        botaoMaisInfo: 'Mais Informações',
        assessoriaAduaneira: 'Assessoria Aduaneira',
        servicosExportacao: 'Serviços de Exportação',
        servicoImportacao: 'Serviços de Importação',
        textoAssessoria: 'Capacitação e compromisso garantem otimização de fluxos e precisão na gestão do processo.',
        textoExportacao: 'Viabilização de negócios para produtos brasileiros no exterior.',
        textoImportacao: 'Parcerias e monitoramento garantem performance, agilidade e redução nos custos em todas as etapas.',
        instagramTag: 'SIGA ',
        contatoTitulo: 'Entre em Contato',
        contatoSubTitulo: 'Agradecemos seu interesse em nossos serviços!',
        seuNome:'Digite seu nome:',
        seuEmail:'Digite seu e-mail:',
        seuTelefone:'Digite seu telefone:',
        seuAssunto:'Assunto:',
        suaMensagem:'Mensagem:',
        botaoEnviar: 'Enviar',
        informacoesGuia: 'Informações',
        telefoneNumero: 'Tel: +55 (27) 3205-6060',
        emailEndereco: 'gestaoaduaneira@gestaoaduaneira.com.br',
        enderecoLocalizacao: 'Rua João Pessoa de Mattos, 505, Ed. Azzurra Office Tower, Sala 501, Praia da Costa, Vila Velha/ES. CEP: 29.101-115',
      },
      servicos: {
        tituloServicos: 'Serviços',
        tituloAssessoriaAduaneira: 'Assessoria Aduaneira',
        textoAssessoriaAduaneira: 'Na assessoria aduaneira auxiliamos nossos clientes a percorrerem o complexo caminho das normas e regulamentações do comércio exterior. Atuamos sendo o setor de importação da empresa contratante, auxiliando no fechamento de câmbio e coordenando todos os trâmites pós compra até o pleno desembaraço e entrega do xml da NF de entrada (BPO completo).',
        tituloServicosExportacao: 'Serviços de Exportação',
        textoServicosExportacao: 'Oferecemos o serviço da análise documental e coordenação de todo o embarque, realizando as licenças e permissões junto aos órgãos anuentes, quando necessário. Realizamos a confecção e registro da Declaração de Exportação, damos suporte na estruturação logística e demais etapas para o pleno desembaraço junto à RFB e embarque dos processos para o exterior.',
        tituloServicosImportacao: 'Serviços de Importação',
        textoServicosImportacao: 'Ofertamos o atendimento em todas as modalidades (Consumo, Drawback, Entreposto Aduaneiro, Admissão temporária e Outros Regimes Aduaneiros especiais), auxiliando na classificação fiscal e tratamento administrativo, análise documental, emissão de licenças e autorizações de importação, registros da Declaração de Importação e demais trâmites necessários para proporcionar segurança e agilidade necessários para o pleno desembaraço da importação.',
      },
      empresa: {
        tituloSobreNos: 'Sobre nós',
        textoSObreNosUm: 'A comissária de assessoria em comércio exterior Gestão Aduaneira foi criada em 1998, com o objetivo de atuar no segmento de desembaraço aduaneiro.',
        textoSObreNosDois: 'Sediada no estado do Espírito Santo, com atuação presente em todos os portos, aeroportos, fronteiras e EADI’S brasileiros. A Gestão Aduaneira possui mais de 100 colaboradores, com estrutura própria de tecnologia, despacho de importação e exportação, financeiro, logística, marketing, jurídico, back office, comercial e gestão de pessoas. Destaca-se em seu segmento por possuir uma equipe especializada, com experiência de mais de 20 anos de mercado.',
        tituloMissao: 'Missão',
        textoMissao: 'Prestar assessoria em serviços de importação e exportação, sendo referência neste mercado a nível nacional e internacional.',
        tituloVisao: 'Visão',
        textoVisao: 'Ser reconhecida como uma empresa sólida e confiável, destacando-se pela agilidade, tecnologia avançada e por possuir uma equipe capacitada, comprometida em prestar serviços de excelência.',
        tituloValores: 'Valores',
        textoValoresUm: 'Foco no cliente;',
        textoValoresDois: 'Experiência tecnológica;',
        textoValoresTres: 'Desenvolvimento dos colaboradores;',
        textoValoresQuatro: 'Sustentabilidade corporativa.',
        tituloAreaAtuacao: 'Segmentos em que atuamos',
        textoAreaAtuacao: 'Atendemos todos os segmentos com muita qualidade, transparência, tecnologia e inovação. Em nossa carteira de clientes, destacam-se as empresas com os seguintes ramos de atividade:',
        tituloBebida: 'Bebidas em geral',
        tituloCosmetico: 'Perfumaria e cosméticos',
        tituloAlcool: 'Indústria alcooleira',
        tituloAutoPeca: 'Autopeças',
        tituloMarmore: 'Mármore e granito',
        tituloEquipamento: 'Máquinas e equipamentos',
        tituloComida: 'Alimentos',
        tituloMedicina: 'Medicamentos',
        tituloBicicleta: 'Partes e peças para bicicletas',
      },
      rodape: {
        Direitos: '2022 GESTÃO ADUANEIRA - TODOS OS DIREITOS RESERVADOS'
      }
    }
  }