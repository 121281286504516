import React from 'react';
import './App.css';
import Rotas from './Rotas';



const App = () => {
  return (
    
        <Rotas/>
    
  );
}

export default App;
