export default {
    translations: {
      cabecalho: {
        empresaMenu: "Our Company",
        servicoMenu: 'Services',
        contatoMenu: 'Contact us'
      },
      PaginaInicial: {
        tituloAduaneira: 'Gestão Aduaneira',
        textoAduaneira: 'We offer the market an efficient and technological concept for the services of customs clearance in import and export cargo processes. More than 20 years of experience focused on providing a customized service to our clients, this allowed GA to be recognized as a reference in customs advisory nationwide.',
        botaoMaisInfo: 'Learn more',
        assessoriaAduaneira: 'Customs Advisory',
        servicosExportacao: 'Export Services',
        servicoImportacao: 'Import Services',
        textoAssessoria: 'Technical capacity and commitment guarantee the optmization of process flows and accuracy in process management.',
        textoExportacao: 'We enable operations for Brazilian products abroad.',
        textoImportacao: 'Partnerships and monitoring guarantee performance, agility and cost-cutting in every stage.',
        instagramTag: 'FOLLOW ',
        contatoTitulo: 'Contact us',
        contatoSubTitulo: 'We appreciate your interest in our services!',
        seuNome:'Name:',
        seuEmail:'E-mail:',
        seuTelefone:'Phone number:',
        seuAssunto:'Theme:',
        suaMensagem:'Message:',
        botaoEnviar: 'Send',
        informacoesGuia: 'Information',
        telefoneNumero: 'Phone number: +55 (27) 3205-6060',
        emailEndereco: 'gestaoaduaneira@gestaoaduaneira.com.br',
        enderecoLocalizacao: 'João Pessoa de Mattos Street, Number 505, Ed. Azzurra Office Tower, Office 501, Praia da Costa, Vila Velha/ES, Brazil. Zip Code: 29.101-115',
      },
      servicos: {
        tituloServicos: 'Services',
        tituloAssessoriaAduaneira: 'Customs Advisory',
        textoAssessoriaAduaneira: 'We help our clients to go through the pathway of norms and regulations of foreign trade. We perform as the import department of the company that hires us, assisting with the remittance of payments and coordinating all the proceedings after the purchase until the clearance of the cargo and delivery of the entrance invoice document (full BPO).',
        tituloServicosExportacao: 'Export Services',
        textoServicosExportacao: 'We offer document analysis and shipment coordination services, managing licenses and permits of licensing authorities, when necessary. We elaborate and register the Export Declaration, provide support to the logistics structuring and to the other steps up to the clearance at the Internal Revenue Service and shipment abroad.',
        tituloServicosImportacao: 'Import Services',
        textoServicosImportacao: 'We attend all types of import processes (Consumption material, Drawback, Bonded warehousing, Temporary Admission and Other Special Regimes), advising on tax classification and administrative treatment, document analysis, import licenses and permits issuance, Import Declaration registration and all the other necessary proceedings to provide security and agility to clear the cargo.',
      },
      empresa: {
        tituloSobreNos: 'About us',
        textoSObreNosUm: 'The foreign trade advisory commission, Gestão Aduaneira, was created in 1998, with purpose of operating in the customs clearance segment.',
        textoSObreNosDois: "Headquartered in the state of Espírito Santo, with presence in all Brazilian ports, airports, borders and EADI's (Bonded Warehouses). Gestão Aduaneira has more than 100 employees, with its own technology structure, import and export dispatch, finance, logistics, marketing, legal, backoffice, commercial and people management. It stands out in its segment for having a specialized team, with more than 20 years of experience in the market.",
        tituloMissao: 'Mission',
        textoMissao: 'To provide advisory in import and export services, being a reference in this market at national and international level.',
        tituloVisao: 'Vision',
        textoVisao: 'To be recognized as a established and reliable company, standing out for its agility, advanced technology, and for having a well-trained team, who’s commited to providing services with excellence.',
        tituloValores: 'Values',
        textoValoresUm: 'Customer focus;',
        textoValoresDois: 'Technological experience;',
        textoValoresTres: 'Employee development;',
        textoValoresQuatro: 'Corporate sustainability.',
        tituloAreaAtuacao: 'Segments in which we operate',
        textoAreaAtuacao: 'We serve all segments with great quality, transparency, technology and innovation. In our client portfolio, companies with the following branches of activity stand out:',
        tituloBebida: 'Beverages',
        tituloCosmetico: 'Perfumery and Cosmetics',
        tituloAlcool: 'Alcohol industry',
        tituloAutoPeca: 'Auto Parts',
        tituloMarmore: 'Marble and Granite',
        tituloEquipamento: 'Machines and Equipments',
        tituloComida: 'Foods',
        tituloMedicina: 'Medicines',
        tituloBicicleta: 'Bicycle parts and accessories',      },
      rodape: {
        Direitos: '2022 GESTÃO ADUANEIRA – ALL RIGHTS RESERVED'
      }
    }
  }